import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { TextField } from "@mui/material";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { gray } from "../../../styling/colors";
import { RootState, dispatch } from "common/redux";
import { setAnswer } from "common/redux/VisitsSlice";
import { useSelector } from "react-redux";
import { isDisabled } from "../VisitHelper";

interface IProps {
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const VisitFieldBillingEntryTimeSpent = ({ field, visit }: IProps) => {
  const disabled = isDisabled(visit);
  const { answers } = useSelector((state: RootState) => state.visits);

  return (
    <TextField
      fullWidth
      type="number"
      label={field.label}
      value={answers[field.field_id]?.toString() ?? ""}
      placeholder={field.placeholder}
      sx={{
        input: {
          color: gray[900]
        }
      }}
      onChange={(e) => {
        if (disabled) return;
        const value = e.target.value.replace(/\D/g, "").replace("-", "");

        const number = +value;

        if (value !== "" && (number < 1 || number > 60)) {
          return;
        } else {
          dispatch(setAnswer({ id: field.field_id, value: value }));
        }
      }}
    />
  );
};

export default VisitFieldBillingEntryTimeSpent;
