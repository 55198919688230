import { DateTime } from "luxon";
import RecurrenceFrequencyEnum from "../enums/Calendaring/Appointments/RecurrenceFrequencyEnum";
import timezones from "common/config/timezones.json";
import UserScheduleType from "../types/UserScheduleType";
import { isFalsy } from "./helpers";
import CalculatedTimeSlot from "../types/Calendaring/CalculatedTimeSlotType";
import AppointmentTypeEnum from "../enums/Calendaring/Appointments/AppointmentTypeEnum";
import RecurrenceUpdateTypeEnum from "../enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";

const CALENDARING_START_DATE = DateTime.fromFormat("2024-01-01", "yyyy-MM-dd");

const TimeOffReasons = [
  AppointmentTypeEnum.SICK_TIME,
  AppointmentTypeEnum.VACATION,
  AppointmentTypeEnum.PERSONAL_APPOINTMENT,
  AppointmentTypeEnum.BREAK_MEAL,
  AppointmentTypeEnum.INTERNAL_MEETING,
  AppointmentTypeEnum.TRAINING,
  AppointmentTypeEnum.BEREAVEMENT,
  AppointmentTypeEnum.JURY_DUTY,
  AppointmentTypeEnum.INCLEMENT_WEATHER
];

const ProviderBlockReasons = [
  AppointmentTypeEnum.NOTE_REVIEW,
  AppointmentTypeEnum.TEAM_MEETING,
  AppointmentTypeEnum.LUNCH,
  AppointmentTypeEnum.PROVIDER_INTAKE
];

function dateIsBlocked(availability) {
  // convert time to ET
  const timezone = "America/New_York";
  const start = DateTime.fromISO(availability.start).setZone(timezone);
  const end = DateTime.fromISO(availability.end).setZone(timezone);

  let isBlocked = false;
  // We want to block 11:30 am - 12:30 pm ET on Thursdays
  if (
    start.weekdayLong === "Thursday" &&
    ((start.hour === 11 && start.minute >= 30) ||
      (start.hour === 12 && start.minute < 30) ||
      (end.hour === 11 && end.minute > 30) ||
      (end.hour === 12 && end.minute <= 30))
  ) {
    isBlocked = true;
  }

  return isBlocked;
}

function getRecurrenceObject(
  recurrence: RecurrenceFrequencyEnum,
  startDate: string,
  endDate: string,
  timezone: string
) {
  const startDateObj = DateTime.fromISO(startDate).setZone(timezone);
  const endDateObj = DateTime.fromISO(endDate).setZone(timezone);
  switch (recurrence) {
    case RecurrenceFrequencyEnum.NEVER:
      return null;
    case RecurrenceFrequencyEnum.WEEKLY:
      return {
        frequency: RecurrenceFrequencyEnum.WEEKLY,
        date_of_week: startDateObj.weekdayLong.toLocaleUpperCase(),
        duration: endDateObj.diff(startDateObj, "minutes").minutes
      };
    case RecurrenceFrequencyEnum.BIWEEKLY:
      return {
        frequency: RecurrenceFrequencyEnum.BIWEEKLY,
        date_of_week: startDateObj.weekdayLong.toLocaleUpperCase(),
        duration: endDateObj.diff(startDateObj, "minutes").minutes
      };
    default:
      return null;
  }
}

function getAmericanTimezone(timezone: string) {
  const zone = timezones.find((tz) => tz.value === timezone);
  return zone?.label ? `${zone.label[0]}T` : "N/A";
}

const defaultPrefObject = {
  MORNING: false,
  MIDDAY: false,
  AFTERNOON: false
};

function transformTimeOfDayPrefArrayToObject(arr) {
  const prefObject = { ...defaultPrefObject };

  arr?.forEach((timeOfDay) => {
    prefObject[timeOfDay] = true;
  });

  return prefObject;
}

const MORNING_SLOTS = [
  {
    availability: {
      start: "8:30",
      end: "9:30"
    }
  },
  {
    availability: {
      start: "9:30",
      end: "10:30"
    }
  }
];

const MIDDAY_SLOTS = [
  {
    availability: {
      start: "10:30",
      end: "11:30"
    }
  },
  {
    availability: {
      start: "11:30",
      end: "12:30"
    }
  },
  {
    availability: {
      start: "1:00",
      end: "2:00"
    }
  }
];

const AFTERNOON_SLOTS = [
  {
    availability: {
      start: "2:00",
      end: "3:00"
    }
  },
  {
    availability: {
      start: "3:00",
      end: "4:00"
    }
  },
  {
    availability: {
      start: "4:00",
      end: "5:00"
    }
  }
];

const WEEKDAYS = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"];

function calculateAvailableTimeslots(
  carerTimezone: string,
  providerScheduleType: UserScheduleType
) {
  if (!carerTimezone || isFalsy(providerScheduleType)) {
    return [];
  }
  const timeslots = [];
  const today = DateTime.now().startOf("minute");
  const start = today
    .setZone(providerScheduleType.timezone)
    .set({
      hour: providerScheduleType.starting_hour,
      minute: providerScheduleType.starting_minutes
    })
    .setZone(carerTimezone);
  const end = today
    .setZone(providerScheduleType.timezone)
    .set({
      hour: providerScheduleType.ending_hour,
      minute: providerScheduleType.ending_minutes
    })
    .setZone(carerTimezone);

  if (!start?.isValid || !end?.isValid || start >= end) {
    return [];
  }

  let currentTime = start;
  while (currentTime <= end) {
    const label = currentTime.toFormat("h:mm a ZZZZ");
    const value = currentTime.toMillis();
    timeslots.push({ label, value });
    currentTime = currentTime.plus({ minutes: 20 });
  }
  return timeslots;
}

/**
 * This function calculates which 20-minute slot(s) in an hour (0, 1, 2) need to be occupied by the provider block.
 * @param startSlotToCheck the start of the time slot to check
 * @param endSlotToCheck the end of the time slot to check
 * @returns Array[] example: [0] or [0, 1], or [1, 2], etc.
 */

function calculateProviderBlockOccupiedSlots(
  currentDateToCheck: DateTime,
  endSlotToCheck: DateTime
) {
  if (
    isFalsy(currentDateToCheck?.isValid) ||
    isFalsy(endSlotToCheck?.isValid) ||
    currentDateToCheck >= endSlotToCheck
  ) {
    return [];
  }
  const occupiedTimeSlotIndices = [];
  const currentDateIsInFirstSlot =
    currentDateToCheck.minute >= 0 && currentDateToCheck.minute < 20;
  const currentDateIsInSecondSlot =
    currentDateToCheck.minute >= 20 && currentDateToCheck.minute < 40;
  const currentDateIsInThirdSlot =
    currentDateToCheck.minute >= 40 && currentDateToCheck.minute <= 59;

  if (currentDateIsInFirstSlot) {
    if (endSlotToCheck.minute <= 20) {
      // 0 - 20 min is occupied
      occupiedTimeSlotIndices.push(0);
    } else if (endSlotToCheck.minute > 20 && endSlotToCheck.minute <= 40) {
      // 0 - 40 min is occupied
      occupiedTimeSlotIndices.push(0, 1);
    } else if (endSlotToCheck.minute > 40 && endSlotToCheck.minute <= 59) {
      // 0 - 60 min is occupied
      occupiedTimeSlotIndices.push(0, 1, 2);
    }
  } else if (currentDateIsInSecondSlot) {
    if (endSlotToCheck.minute <= 40) {
      // 20 - 40 min is occupied
      occupiedTimeSlotIndices.push(1);
    } else if (endSlotToCheck.minute > 40 && endSlotToCheck.minute <= 59) {
      // 20 - 60 min is occupied
      occupiedTimeSlotIndices.push(1, 2);
    }
  } else if (currentDateIsInThirdSlot) {
    if (endSlotToCheck.minute <= 59) {
      // 40 - 60 min is occupied
      occupiedTimeSlotIndices.push(2);
    }
  }
  return occupiedTimeSlotIndices;
}

/**
 * This function calculates the different time slots for a provider block event.
 * @param startDate string the event start date as an ISO string
 * @param endDate string the event end date as an ISO string
 * @param scheduleType the schedule type of the provider
 * @returns CalculatedTimeSlot[]
 */

function calculateTimeSlotsForProviderBlock(
  startDate: string,
  endDate: string,
  scheduleType: UserScheduleType
): CalculatedTimeSlot[] {
  if (isFalsy(startDate) || isFalsy(endDate) || isFalsy(scheduleType)) {
    return [];
  }
  const eventStartDate = DateTime.fromISO(startDate)?.setZone("utc");
  const eventEndDate = DateTime.fromISO(endDate)?.setZone("utc");

  if (
    isFalsy(eventStartDate?.isValid) ||
    isFalsy(eventEndDate?.isValid) ||
    eventStartDate >= eventEndDate
  ) {
    return [];
  }

  let currentDateToCheck = eventStartDate;

  const slots: CalculatedTimeSlot[] = [];

  while (
    currentDateToCheck?.day <= eventEndDate?.day &&
    currentDateToCheck?.hour < eventEndDate?.hour
  ) {
    const startOfHour = currentDateToCheck.startOf("hour");
    const endOfHour = currentDateToCheck.endOf("hour");
    const endOfTimeSlot = currentDateToCheck.startOf("hour").plus({ hours: 1 });
    // check to make sure start and end date are within the schedule range
    if (
      startOfHour >=
        startOfHour.setZone(scheduleType.timezone).set({
          hour: scheduleType.starting_hour,
          minute: scheduleType.starting_minutes
        }) &&
      endOfHour <=
        endOfHour.setZone(scheduleType.timezone).set({
          hour: scheduleType.ending_hour,
          minute: scheduleType.ending_minutes
        })
    ) {
      // check for which 20-minute slot(s) need to be occupied by the provider block
      const occupiedTimeSlotIndices = calculateProviderBlockOccupiedSlots(
        currentDateToCheck,
        endOfHour
      );
      const timeSlotForThisHour = `${startOfHour.toISO()}-${endOfTimeSlot.toISO()}`;

      const timeslotStartDateObject = startOfHour;
      const timeslotEndDateObject = endOfTimeSlot;
      slots.push({
        timeSlotForThisHour,
        timeslotStartDateObject,
        timeslotEndDateObject,
        occupiedTimeSlotIndices
      });
    }

    currentDateToCheck = currentDateToCheck.startOf("hour").plus({ hours: 1 });
  }

  if (
    currentDateToCheck.day === eventEndDate.day &&
    currentDateToCheck.hour === eventEndDate.hour &&
    currentDateToCheck.minute < eventEndDate.minute
  ) {
    const startOfHour = currentDateToCheck.startOf("hour");
    const endOfTimeSlot = currentDateToCheck.startOf("hour").plus({ hours: 1 });
    // check for which 20-minute slot(s) need to be occupied by the provider block
    const occupiedTimeSlotIndices = calculateProviderBlockOccupiedSlots(
      currentDateToCheck,
      eventEndDate
    );
    const timeSlotForThisHour = `${startOfHour.toISO()}-${endOfTimeSlot.toISO()}`;

    const timeslotStartDateObject = startOfHour;
    const timeslotEndDateObject = endOfTimeSlot;
    slots.push({
      timeSlotForThisHour,
      timeslotStartDateObject,
      timeslotEndDateObject,
      occupiedTimeSlotIndices
    });
  }

  return slots;
}

function getStringFromStartEndISODateStrings(
  startdate: string,
  enddate: string,
  selectedProviderTimezone: string,
  showDate: boolean
) {
  const start = DateTime.fromISO(startdate)?.setZone(selectedProviderTimezone);
  const end = DateTime.fromISO(enddate)?.setZone(selectedProviderTimezone);
  if (isFalsy(start?.isValid) || isFalsy(end?.isValid)) {
    return "N/A";
  }

  const startString = start.toFormat("h:mm a");
  const endString = showDate
    ? end.toFormat("h:mm a ZZZZ MM/dd/yyyy")
    : end.toFormat("h:mm a ZZZZ");
  return `${startString} - ${endString}`;
}

function isTimeOffAppointment(appointment_type: AppointmentTypeEnum) {
  return TimeOffReasons.includes(appointment_type);
}

function isProviderBlockAppointment(appointment_type: AppointmentTypeEnum) {
  return ProviderBlockReasons.includes(appointment_type);
}

function isNurseAppointment(appointment_type: AppointmentTypeEnum) {
  return [
    AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP,
    AppointmentTypeEnum.NURSE_FOLLOWUP
  ].includes(appointment_type);
}

function isProviderAppointment(appointment_type: AppointmentTypeEnum) {
  return [AppointmentTypeEnum.PROVIDER_FOLLOWUP].includes(appointment_type);
}

function isMemberAppointment(appointment_type: AppointmentTypeEnum) {
  return (
    appointment_type === AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP ||
    appointment_type === AppointmentTypeEnum.NURSE_FOLLOWUP ||
    appointment_type === AppointmentTypeEnum.PROVIDER_FOLLOWUP
  );
}

const recurrenceValues = [
  { value: RecurrenceFrequencyEnum.NEVER, label: "Never" },
  { value: RecurrenceFrequencyEnum.WEEKLY, label: "Every week" },
  { value: RecurrenceFrequencyEnum.BIWEEKLY, label: "Every two weeks" }
];

const recurrenceUpdateTypeValues = [
  { value: RecurrenceUpdateTypeEnum.ONCE, label: "This event" },
  {
    value: RecurrenceUpdateTypeEnum.THIS_EVENT_FORWARD,
    label: "This event and following events"
  }
];

function isEvenBiweeklyRecurrence(date: DateTime) {
  // To determine which week the recurrence apply to,
  //we'll calculate from 1/1/2024 (right before we deployed to prod) because odd and even
  //weeks will change depending on the year.  So if we have a defined start,
  //we can then determine if it is an odd or even week
  // 1/1/2024 is an even week

  // https://github.com/CopilotIQ/calendar-lambdas/blob/3bcf73bec45f8f79018b3ac45316a42bb9473dd8/calendar/event/create_event.go#L365-L377

  // If the modulo is 0, then it is an even week, if it is 1 it is odd
  return Math.floor(date.diff(CALENDARING_START_DATE, "weeks").weeks % 2) === 0;
}

function isSameWeek(date1: DateTime, date2: DateTime) {
  if (!date1.isValid || !date2.isValid) {
    return false;
  }

  return date1.startOf("week") === date2.startOf("week");
}

export {
  dateIsBlocked,
  getRecurrenceObject,
  getAmericanTimezone,
  transformTimeOfDayPrefArrayToObject,
  defaultPrefObject,
  MORNING_SLOTS,
  MIDDAY_SLOTS,
  AFTERNOON_SLOTS,
  WEEKDAYS,
  calculateAvailableTimeslots,
  calculateTimeSlotsForProviderBlock,
  getStringFromStartEndISODateStrings,
  isTimeOffAppointment,
  isProviderBlockAppointment,
  isNurseAppointment,
  isProviderAppointment,
  isMemberAppointment,
  TimeOffReasons,
  ProviderBlockReasons,
  recurrenceValues,
  recurrenceUpdateTypeValues,
  isEvenBiweeklyRecurrence,
  isSameWeek
};
