import { Alert, Slide, SlideProps, Snackbar, SnackbarCloseReason, Typography } from "@mui/material";

const slideTransition = (props: SlideProps) => (
  <Slide {...props} direction="up" />
);

const DEFAULT_DURATION = 6000;

export const RIQSnackbar = ({
    open,
    content,
    severity,
    handleClose
  }: {
    open: boolean;
    content: any;
    severity: "success" | "info" | "warning" | "error";
    handleClose: () => void;
  }) => {
    return (
      <Snackbar
        open={open}
        autoHideDuration={DEFAULT_DURATION}
        onClose={(
            event: React.SyntheticEvent | Event,
            reason?: SnackbarCloseReason
          ) => {
            if (reason === "clickaway") {
              return;
            }
        
            handleClose();
          }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={slideTransition}
      >
        <Alert severity={severity} variant="filled" sx={{ width: "100%" }}>
            <Typography variant="body1">
            {content}
            </Typography>
          
        </Alert>
      </Snackbar>
    );
  };
