import ReduxTagEnum from "../enums/ReduxTagEnum";
import { apiMemberEngagement } from "./AxiosService";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { AppDispatch } from "../redux";

async function clearCache(
  queryFulfilled: PromiseWithKnownReason<any, any>,
  dispatch: AppDispatch
) {
  try {
    await queryFulfilled;
    setTimeout(() => {
      dispatch(
        memberEngagementService.util.invalidateTags([
          ReduxTagEnum.MemberEngagement
        ])
      );
    }, 3000);
  } catch (error) {}
}

const memberEngagementService = apiMemberEngagement
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.MemberEngagement]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      sendJoinVideoCallMessage: builder.mutation<
        { MessageBody: string },
        Omit<
          {
            url?: string;
            content?: string;
            member_id: string;
            action: "JOIN_VIDEO_CALL" | "ON_DEMAND_APPOINTMENT_REMINDER" | "CARE_MESSAGE";
          },
          "id"
        >
      >({
        query: ({ url, member_id, action, content }) => {
          const data = {
            patient_id: member_id,
            channel_type: "SMS",
            message_variables: {
              VIDEO_URL: url,
              CARE_MESSAGE_CONTENT: content,
            }
          };
          return {
            url: `/patient_messages/${action}/patient/${member_id}`,
            method: "POST",
            data
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch);
        }
      })
    })
  });

export const { useSendJoinVideoCallMessageMutation } = memberEngagementService;
