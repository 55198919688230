import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import { useGetReadingsTrendsByMemberQuery } from "common/services/ReadingsService";
import MemberType from "common/types/MemberType";
import { useEffect, useState } from "react";
import { isFalsy, isTruthy } from "common/helpers/helpers";
import { useAppDispatch } from "common/redux";
import { setAnswers } from "common/redux/VisitsSlice";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { Column, StatusBadge } from "../../../styling/StyleComponents";
import { Box, LinearProgress, Typography } from "@mui/material";
import { CarePlanConditionsEnum } from "common/types/Visits/CarePlanType";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { gray } from "../../../styling/colors";
import { useCollapse } from "react-collapsed";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import GlucoseChart from "../../MemberDetails/Readings/GlucoseChart";
import VisitFieldMemberReadingsReview from "./VisitFieldMemberReadingsReview";
import BloodPressureChart from "../../MemberDetails/Readings/BloodPressureChart";

interface IProps {
  member: MemberType;
  visit: GetVisitResponseType;
}

const DELAY_REVIEW_READINGS = 3000;

const VisitFieldReadingTrends = ({ member, visit }: IProps) => {
  const dispatch = useAppDispatch();

  const [pause, setPause] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(10);
  const [bpDateFilter, setBPDateFilter] = useState<number>(7);
  const [glucoseDateFilter, setGlucoseDateFilter] = useState<number>(7);

  const { data, isLoading, isSuccess } = useGetReadingsTrendsByMemberQuery(
    {
      memberId: member?.patient?.patient_id,
      paramsObject: {
        device_type: [
          DeviceTrendParam.BLOOD_PRESSURE,
          DeviceTrendParam.GLUCOSE
        ],
        trend_days: [7, 30]
      }
    },
    { skip: isFalsy(member?.patient?.patient_id) }
  );

  const getValue = ({
    type,
    days,
    trends
  }: {
    type: DeviceTrendParam;
    days: number;
    trends: any;
  }) => {
    if (type === DeviceTrendParam.GLUCOSE) {
      const avgFasting =
        trends?.average_fasting < 0 ? "No data" : trends?.average_fasting;
      const avgNonFasting =
        trends?.average_non_fasting < 0
          ? "No data"
          : trends?.average_non_fasting;

      return `Glucose ${days.toString()}-day fasting average: ${avgFasting}, non-fasting average: ${avgNonFasting}`;
    } else if (type === DeviceTrendParam.BLOOD_PRESSURE) {
      const bp =
        isFalsy(trends?.average_systolic) || isFalsy(trends.average_diastolic)
          ? "No data"
          : `${trends?.average_systolic} / ${trends?.average_diastolic}`;

      return `Blood Pressure ${days.toString()}-day average: ${bp}`;
    }
    return "";
  };

  const forceTrendAnswers = () => {
    let res = [];

    // PRECALL_GLUCOSE_7D_AVG
    if (isTruthy(data?.glucose) && isTruthy(data?.glucose?.trends[7])) {
      res.push({
        id: CareFlowFieldIdsEnum.PRECALL_GLUCOSE_7D_AVG,
        value: getValue({
          type: DeviceTrendParam.GLUCOSE,
          days: 7,
          trends: data?.glucose?.trends[7]
        })
      });
    }

    // PRECALL_GLUCOSE_30D_AVG
    if (isTruthy(data?.glucose) && isTruthy(data?.glucose?.trends[30])) {
      res.push({
        id: CareFlowFieldIdsEnum.PRECALL_GLUCOSE_30D_AVG,
        value: getValue({
          type: DeviceTrendParam.GLUCOSE,
          days: 30,
          trends: data?.glucose?.trends[30]
        })
      });
    }

    // PRECALL_BLOOD_PRESSURE_7D_AVG
    if (
      isTruthy(data?.blood_pressure) &&
      isTruthy(data?.blood_pressure?.trends[7])
    ) {
      res.push({
        id: CareFlowFieldIdsEnum.PRECALL_BLOOD_PRESSURE_7D_AVG,
        value: getValue({
          type: DeviceTrendParam.BLOOD_PRESSURE,
          days: 7,
          trends: data?.blood_pressure?.trends[7]
        })
      });
    }

    // PRECALL_BLOOD_PRESSURE_30D_AVG
    if (
      isTruthy(data?.blood_pressure) &&
      isTruthy(data?.blood_pressure?.trends[30])
    ) {
      res.push({
        id: CareFlowFieldIdsEnum.PRECALL_BLOOD_PRESSURE_30D_AVG,
        value: getValue({
          type: DeviceTrendParam.BLOOD_PRESSURE,
          days: 30,
          trends: data?.blood_pressure?.trends[30]
        })
      });
    }

    dispatch(setAnswers(res));
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress !== 100)
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 25
        );
    }, 600);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setPause(false);
      forceTrendAnswers();
    }, DELAY_REVIEW_READINGS);
    setTimeout(() => {
      forceTrendAnswers();
    }, DELAY_REVIEW_READINGS * 2);
  }, [data, isSuccess]);
  const {
    getCollapseProps: bpCollapseProps,
    getToggleProps: bpToggleProps,
    isExpanded: isBPExpanded
  } = useCollapse({
    defaultExpanded: false
  });

  const {
    getCollapseProps: glucoseCollapseProps,
    getToggleProps: glucoseToggleProps,
    isExpanded: isGlucoseExpanded
  } = useCollapse({
    defaultExpanded: false
  });

  return (
    <Column>
      {pause ? (
        <Box margin={"30px 0px"}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="caption" textAlign={"center"}>
            Loading reading data...
          </Typography>
        </Box>
      ) : (
        <>
          <Typography variant="body1" color={gray[900]} marginBottom={"10px"}>
            The member has the following conditions:
          </Typography>
          <Flexbox gap={"10px"} mb={"10px"}>
            {member?.care_plan?.conditions?.map((item) => {
              return <StatusBadge key={item} status={item} hideDot={true} />;
            })}
            {(member?.care_plan == null ||
              member?.care_plan?.conditions?.length === 0) && (
              <Typography
                variant="h6"
                color="text.secondary"
                textAlign={"center"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                The member has no conditions
              </Typography>
            )}
          </Flexbox>
          <VisitFieldMemberReadingsReview />
        </>
      )}
      {!pause &&
        !isLoading &&
        member?.care_plan?.conditions?.includes(
          CarePlanConditionsEnum.DIABETES
        ) && (
          <>
            <Box
              display={"flex"}
              sx={{ cursor: "pointer" }}
              {...glucoseToggleProps()}
            >
              {isGlucoseExpanded ? (
                <ExpandLess
                  fontSize={"small"}
                  sx={{ margin: "15px 5px 10px 0px" }}
                />
              ) : (
                <ExpandMore
                  fontSize={"small"}
                  sx={{ margin: "15px 5px 10px 0px" }}
                />
              )}
              <Typography
                variant="h6"
                color={gray[900]}
                textTransform={"uppercase"}
                margin={"15px 5px 10px 0px"}
              >
                Review Glucose Readings
              </Typography>
            </Box>
            <Column {...glucoseCollapseProps()}>
              <GlucoseChart
                patient={member}
                defaultDays={7}
                dateFilter={glucoseDateFilter}
                hideNonDateControls={true}
                showTableOverride={true}
                handleDataChange={({ dateFilter }) =>
                  setGlucoseDateFilter(dateFilter)
                }
              />
            </Column>
          </>
        )}
      {!pause &&
        !isLoading &&
        member?.care_plan?.conditions?.includes(
          CarePlanConditionsEnum.HYPERTENSION
        ) && (
          <>
            <Box
              display={"flex"}
              sx={{ cursor: "pointer" }}
              {...bpToggleProps()}
            >
              {isBPExpanded ? (
                <ExpandLess
                  fontSize={"small"}
                  sx={{ margin: "15px 5px 10px 0px" }}
                />
              ) : (
                <ExpandMore
                  fontSize={"small"}
                  sx={{ margin: "15px 5px 10px 0px" }}
                />
              )}
              <Typography
                variant="h6"
                color={gray[900]}
                textTransform={"uppercase"}
                margin={"15px 5px 10px 0px"}
              >
                Review Blood Pressure Readings
              </Typography>
            </Box>
            <Column {...bpCollapseProps()}>
              <BloodPressureChart
                patient={member}
                defaultDays={7}
                dateFilter={bpDateFilter}
                hideNonDateControls={true}
                showTableOverride={true}
                handleDataChange={({ dateFilter }) =>
                  setBPDateFilter(dateFilter)
                }
              />
            </Column>
          </>
        )}
    </Column>
  );
};

export default VisitFieldReadingTrends;
