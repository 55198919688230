import { forwardRef, Ref, useImperativeHandle, useMemo } from "react";
import { DateTime } from "luxon";

import Table from "../../../components/Table/Table";
import { CircularProgress, Typography } from "@mui/material";
import ReportRefInterface from "./ReportRefInterface";
import RolesEnum, { getRoleLabel } from "common/enums/RolesEnum";
import MemberType from "common/types/MemberType";
import ErrorComponent from "../../../components/ErrorComponent";
import { isFalsy } from "common/helpers/helpers";
import { useGetEncountersQuery } from "common/services/EncountersService";
import EncounterSubmitterType from "common/types/EncounterSubmitterType";

const encountersColumn = [
  { name: "encounterDate" },
  { name: "encounterDurationReport" },
  { name: "encounterSubmitterRole" }
];

interface IProps {
  patient: MemberType;
  startDate: DateTime;
  endDate: DateTime;
}

const SummaryComponent = ({ data }: { data: EncounterSubmitterType[] }) => {
  const totalDuration = useMemo(() => getTotalDuration(data), [data]);

  return (
    <>
      <Typography variant="body1" color="text.secondary">
        {`Total Duration: ${totalDuration} minutes`}
        <br />
        {`Total Appointments: ${data.length}`}
      </Typography>
    </>
  );
};

// TBD do we need to update this to handle NP encounters with talk and total time?
const getTotalDuration = (data) => {
  const totalDuration = data.reduce((prevValue, currentValue) => {
    let result = prevValue;
    if (typeof currentValue?.visit?.total_time === "number") {
      result = result + currentValue.visit?.total_time;
    } else if (typeof currentValue?.encounter?.duration === "number") {
      result = result + currentValue.encounter.duration;
    }
    return result;
  }, 0);
  return totalDuration;
};

const ReportEncounters = forwardRef(
  ({ patient, startDate, endDate }: IProps, ref: Ref<ReportRefInterface>) => {
    const { data, isError, isLoading, isSuccess, error } =
      // tbd delete the below lines once we switch to encounter visits

      useGetEncountersQuery(
        {
          patient_id: patient?.patient.patient_id,
          startsAfter: startDate,
          startsBefore: endDate
        },
        { skip: isFalsy(patient) }
      );
    // tbd comment this back in when we are ready to use encounter visits
    // useGetEncounterVisitsQuery(
    //   {
    //     patient_id: patient?.patient.patient_id,
    //     encounter_starts_on_after: startDate,
    //     encounter_starts_on_before: endDate
    //   },
    //   { skip: isFalsy(patient) }
    // );

    useImperativeHandle(ref, () => ({
      getCSVReportData() {
        const formattedData = data.map((item) => {
          return [
            DateTime.fromISO(item.encounter.starts_on).toFormat("MM/dd/yyyy"),
            item.encounter?.duration?.toString(),
            item.encounter.submitter_roles
              .map((role: RolesEnum) => getRoleLabel(role))
              .join(", "),
            item.encounter.submitted_by
          ];
        });
        return {
          filename: "encounters",
          columns: ["Date", "Duration (min)", "Submitter Role", "Submitter Id"],
          data: formattedData
        };
      },
      getReportData: () => {
        const totalDuration = getTotalDuration(data);

        const formattedData = data.map((item) => {
          return [
            DateTime.fromISO(item.encounter.starts_on).toFormat("MM/dd/yyyy"),
            item.encounter?.duration?.toString(),
            item.encounter.submitter_roles
              .map((role: RolesEnum) => getRoleLabel(role))
              .join(", ")
          ];
        });

        return [
          {
            columnNames: ["Date", "Duration (min)", "Submitter Role"],
            data: formattedData,
            reportSummary: [
              `Total Duration: ${totalDuration} minutes`,
              `Total Appointments: ${data.length}`
            ]
          }
        ];
      }
    }));

    return (
      <>
        {data && data.length > 0 && (
          <>
            <Table
              tableColumns={encountersColumn}
              data={data}
              tableMaxHeight={"none"}
            />
            <br />
            <SummaryComponent data={data} />
          </>
        )}

        {isLoading && <CircularProgress />}
        {isSuccess && data && data.length === 0 && (
          <ErrorComponent error="No data found." />
        )}
        {isError && <ErrorComponent error={error} />}
      </>
    );
  }
);

export default ReportEncounters;
