const EnvVars = {
  REACT_APP_PRODUCT: process.env.REACT_APP_PRODUCT,

  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_STACK_DEPLOYMENT_ENV: process.env.REACT_APP_STACK_DEPLOYMENT_ENV,
  REACT_APP_SAVE_NAVIGATION_STATE: process.env.REACT_APP_SAVE_NAVIGATION_STATE,
  REACT_APP_MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_SEGMENT_API_KEY: process.env.REACT_APP_SEGMENT_API_KEY,
  REACT_APP_SEGMENT_ENABLED: process.env.REACT_APP_SEGMENT_ENABLED === "true",
  REACT_APP_WEB_PUSH_CERTIFICATE: process.env.REACT_APP_WEB_PUSH_CERTIFICATE,
  REACT_APP_BUILD_DATETIME: process.env.REACT_APP_BUILD_DATETIME,

  // RemoteIQ only EnvVars
  REACT_APP_ATHENA_ROOT_URL: process.env.REACT_APP_ATHENA_ROOT_URL,
  REACT_APP_ATHENA_PRACTICE_ID: process.env.REACT_APP_ATHENA_PRACTICE_ID
};

export default EnvVars;
