import { useEffect, useState } from "react";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { Column } from "../../../styling/StyleComponents";
import { getField } from "../VisitHelper";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { Typography, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";

const StyledAgendaSection = styled("li")`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-family: Inter;
`;

interface IProps {
  visit: GetVisitResponseType;
}

enum AgendaSections {
  CRITICAL_ISSUES = "Critical Issues",
  GOALS_COACHING = "Goals and Coaching [mandatory]",
  REFILLS = "Refills (supplies)",
  REFERRAL = "Referral (CopilotIQ Provider)",
  NEXT_APPOINTMENTS = "Next Appointments [mandatory]",
  REVIEW = "Review and Send Action Items [mandatory]"
}

interface AgendaBody {
  section: string;
  subsections: string[];
}

interface Agenda {
  body: AgendaBody[];
}

const VisitFieldAgendaSetting = ({ visit }: IProps) => {
  const { answers } = useSelector((state: RootState) => state.visits);
  const [agenda, setAgenda] = useState<Agenda>(null);

  useEffect(() => {
    if (visit) {
      calculateAgenda();
    }
  }, [visit]);

  // Based on the fields selected in the visit, update the body of the agenda
  const calculateAgenda = () => {
    const agendaSections = [];

    // Critical Issues
    const criticalIssuesSubsections = [];
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_CHEST_PAIN,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_SHORTNESS_OF_BREATH,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_SEVERE_HEADACHE,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_CONFUSION,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_LOSS_OF_CONSCIOUSNESS,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_FACIAL_DROOP,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_SEIZURE,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_GI_ISSUES,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_SUICIDAL_THOUGHTS,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_ABUSE,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_OTHER,
      criticalIssuesSubsections,
      true
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_DEVICE_ISSUE_QUESTIONS,
      criticalIssuesSubsections
    );

    agendaSections.push({
      section: AgendaSections.CRITICAL_ISSUES,
      subsections: criticalIssuesSubsections
    });

    // Goals & Coaching - MANDATORY
    const goalsCoachingSubsections = ["Goals", "Clinical Coaching"];
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_DEVICE_USAGE_QUESTIONS,
      goalsCoachingSubsections
    );
    addLabelIfChecked(
      CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_REQUESTS_CLINICAL_COACHING,
      goalsCoachingSubsections
    );

    agendaSections.push({
      section: AgendaSections.GOALS_COACHING,
      subsections: goalsCoachingSubsections
    });

    // Referral
    agendaSections.push({ section: AgendaSections.REFERRAL });

    // Refills
    if (isFieldChecked(CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_NEEDS_REFILLS)) {
      agendaSections.push({ section: AgendaSections.REFILLS });
    }

    // Next Appointments
    agendaSections.push({ section: AgendaSections.NEXT_APPOINTMENTS });

    // Review & Send Action Items
    agendaSections.push({ section: AgendaSections.REVIEW });

    setAgenda({ body: agendaSections });
  };

  // If the provided fieldId is checked, add the label of that field to the provided subsection array
  const addLabelIfChecked = async (
    fieldId,
    subsections,
    isCriticalSymptom = false
  ) => {
    const lookupField = await getField(visit?.care_flow, fieldId);
    const lookupAnswer = answers[fieldId];

    if (lookupAnswer === "true" || lookupAnswer === true) {
      const labelPrefix = isCriticalSymptom ? "Critical Symptom: " : "";
      subsections.push(`${labelPrefix}${lookupField?.label}`);
    }
  };

  const isFieldChecked = (fieldId) => {
    const lookupAnswer = answers[fieldId];
    return lookupAnswer === "true" || lookupAnswer === true;
  };

  return (
    <Column>
      <ol key={1}>
        {agenda?.body?.map((section) => {
          return (
            <>
              <StyledAgendaSection>
                <Typography variant={"h6"}>{section.section}</Typography>
              </StyledAgendaSection>
              {section?.subsections?.length > 0 && (
                <ul key={section.section}>
                  {section?.subsections?.map((subsection, i) => {
                    return (
                      <li key={i}>
                        <Typography variant={"body1"} data-testid={subsection}>
                          {subsection}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          );
        })}
      </ol>
    </Column>
  );
};

export default VisitFieldAgendaSetting;
