export enum GoalAssessmentStatusEnum {
  ON_TRACK = "ON_TRACK",
  OFF_TRACK = "OFF_TRACK",
  NOT_STARTED = "NOT_STARTED",
  NO_DATA = "NO_DATA",
  COMPLETED = "COMPLETED"
}

enum GoalAssessmentPrettyEnum {
  OffTrack = "Off Track",
  OnTrack = "On Track",
  NoData = "No Data"
}

export enum GoalStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export enum GoalCategoryEnum {
  DIABETES = "DIABETES",
  HYPERTENSION = "HYPERTENSION",
  LIFESTYLE = "LIFESTYLE",
  MEDICATION = "MEDICATION",
  NUTRITION = "NUTRITION",
  EXERCISE = "EXERCISE"
}

export const getGoalCategoriesByType = (goalType) => {
  if (goalType === GoalTypeEnum.CLINICAL)
    return [GoalCategoryEnum.DIABETES, GoalCategoryEnum.HYPERTENSION];
  else
    return [
      GoalCategoryEnum.LIFESTYLE,
      GoalCategoryEnum.MEDICATION,
      GoalCategoryEnum.NUTRITION,
      GoalCategoryEnum.EXERCISE
    ];
};

export const getGoalTitleByCategory = (goalCategory) => {
  switch (goalCategory) {
    case GoalCategoryEnum.DIABETES:
      return "Manage Blood Sugar";
    case GoalCategoryEnum.HYPERTENSION:
      return "Manage Blood Pressure";
    case GoalCategoryEnum.EXERCISE:
      return "Increase Physical Activity";
    case GoalCategoryEnum.NUTRITION:
      return "Improve Diet";
    case GoalCategoryEnum.LIFESTYLE:
      return "Make Healthier Choices";
    case GoalCategoryEnum.MEDICATION:
      return "Manage Medication";
    default:
      return "";
  }
};

export const getPrettyGoalAssessmentStatus = (goalAssessment) => {
  switch (goalAssessment) {
    case GoalAssessmentStatusEnum.COMPLETED:
      return "Completed";
    case GoalAssessmentStatusEnum.ON_TRACK:
      return "On Track";
    case GoalAssessmentStatusEnum.OFF_TRACK:
      return "Off Track";
    case GoalAssessmentStatusEnum.NOT_STARTED:
      return "Not Started";
    case GoalAssessmentStatusEnum.NO_DATA:
      return "No Data";
    default:
      return "";
  }
};

export enum GoalTypeEnum {
  CLINICAL = "CLINICAL",
  MEMBER = "MEMBER"
}

export interface GoalAssessmentType {
  assessment_id?: string;
  goal_id: string;
  assessed_by?: string;
  assessed_on?: string;
  assessment_status?: string;
  current_value?: string;
  notes?: string;
}

export interface GoalType {
  goal_description?: string;
  goal_id?: string;
  goal_status?: GoalStatusEnum;
  goal_type: GoalTypeEnum;
  target_value?: string;
  created_by?: string;
  created_on?: string;
  modified_by?: string;
  modified_on?: string;
  goal_category?: GoalCategoryEnum;
  latest_assessments?: GoalAssessmentType[];
}
