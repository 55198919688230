enum CareFlowFieldComponentEnum {
  GLUCOSE_READING_CHART = "GLUCOSE_READING_CHART",
  BLOOD_PRESSURE_READING_CHART = "BLOOD_PRESSURE_READING_CHART",
  PULSE_READING_CHART = "PULSE_READING_CHART",
  OXIMETER_READING_CHART = "OXIMETER_READING_CHART",
  WEIGHT_SCALE_READING_CHART = "WEIGHT_SCALE_READING_CHART",
  MISSING_AGREEMENTS_TABLE = "MISSING_AGREEMENTS_TABLE",
  ORDER_HISTORY = "ORDER_HISTORY",
  CONNECT_WITH_MEMBER = "CONNECT_WITH_MEMBER",
  PROVIDER_DROPDOWN = "PROVIDER_DROPDOWN",
  MEMBER_RELATIONSHIP_NOTES = "MEMBER_RELATIONSHIP_NOTES",
  UPCOMING_APPOINTMENTS = "UPCOMING_APPOINTMENTS",
  BILLING_ENTRY_NURSE = "BILLING_ENTRY_NURSE",
  BILLING_ENTRY_TIME_ENTRY = "BILLING_ENTRY_TIME_ENTRY",
  GENERATE_NOTES = "GENERATE_NOTES",
  START_ENCOUNTER = "START_ENCOUNTER",
  END_ENCOUNTER = "END_ENCOUNTER",
  QUOTE = "QUOTE",
  IN_LICENSED_STATE = "IN_LICENSED_STATE",
  READING_TRENDS_BACKGROUND = "READING_TRENDS_BACKGROUND",
  BLOOD_PRESSURE_7D_AVG = "BLOOD_PRESSURE_7D_AVG",
  GLUCOSE_7D_AVG = "GLUCOSE_7D_AVG",
  BLOOD_PRESSURE_30D_AVG = "BLOOD_PRESSURE_30D_AVG",
  GLUCOSE_30D_AVG = "GLUCOSE_30D_AVG",
  ORDER_REFILLS = "ORDER_REFILLS",
  TAB = "TAB",
  TAB_GROUP = "TAB_GROUP",
  DATETIME = "DATETIME",
  DYNAMIC_AGENDA_SETTING = "DYNAMIC_AGENDA_SETTING",
  DYNAMIC_CRITICAL_ISSUES = "DYNAMIC_CRITICAL_ISSUES",
  GOAL_MANAGEMENT = "GOAL_MANAGEMENT",
  DEVICE_SETUP_GUIDE = "DEVICE_SETUP_GUIDE",
  PRECALL_LAST_VISIT_FOLLOWUP = "PRECALL_LAST_VISIT_FOLLOWUP",
  CARE_PLAN_WEEKLY_VISITS = "CARE_PLAN_WEEKLY_VISITS",
  URGENT_ALERTS_BANNERS = "URGENT_ALERTS_BANNERS",
  URGENT_ALERT_ACTIONS = "URGENT_ALERT_ACTIONS",
  MEMBER_READINGS_REVIEW = "MEMBER_READINGS_REVIEW",
  CARE_PLAN_WEEKLY_VISITS_CONVINCE_QUOTE = "CARE_PLAN_WEEKLY_VISITS_CONVINCE_QUOTE",
  CLINICAL_GOAL_SUMMARY = "CLINICAL_GOAL_SUMMARY",
  NOT_CONNECTED_CARE_MESSAGE = "NOT_CONNECTED_CARE_MESSAGE",
  RECENT_ENCOUNTERS = "RECENT_ENCOUNTERS",
}

export default CareFlowFieldComponentEnum;
