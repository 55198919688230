import { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import {
  Typography,
  TextField,
  MenuItem,
  Autocomplete,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel
} from "@mui/material";
import styled from "@emotion/styled";

import {
  StyledModal,
  ModalBody,
  BodyHeader,
  ModalFooter,
  ModalFooterButtons
} from "../../styling/StyleModal";

import {
  CustomTooltip,
  TurqoiseButton,
  WhiteButton
} from "../../styling/StyleComponents";

import ErrorComponent from "../../components/ErrorComponent";
import MemberType from "common/types/MemberType";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import { useGetUserWithQueryParamsQuery } from "common/services/UserService";
import RolesEnum, {
  isProviderRole,
  TH_Roles,
  NP_Roles,
  hasProviderRole,
  hasTnRole,
  hasIntakeNurseRole
} from "common/enums/RolesEnum";
import { getNameOrUsername, isTruthy } from "common/helpers/helpers";
import { RootState, useAppDispatch } from "common/redux";
import { useSelector } from "react-redux";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import {
  getComplexityTypes,
  newEncounterReasons,
  roleHasMultipleMatches,
  tnVisitTypeReasons,
  VideoCallDeclineReasons
} from "common/helpers/EncounterHelper";
import MemberStatusEnum from "common/enums/MemberStatusEnum";

import DateTimePicker from "../../components/DateTimePicker";
import UserType from "common/types/UserType";
import { useFormik } from "formik";
import VisitReasonsEnum from "common/enums/Calendaring/Visits/VisitReasonsEnum";
import VideoCallDeclineReasonEnum from "common/enums/Calendaring/Visits/VideoCallDeclineReasonEnum";
import CommunicationTypeEnum from "common/enums/Calendaring/CommunicationTypeEnum";
import { useCreateEncounterMutation } from "common/services/EncountersService";
import { Flexbox } from "../../styling/NewStyleComponents";
import { gray } from "../../styling/colors";
import {
  InfoOutlined,
  PhoneOutlined,
  VideocamOutlined
} from "@mui/icons-material";
import NumberInput from "../../components/Input/NumberInput";
import { StyledSelect } from "../../components/Table/helpers/TableFilterHelpers";
import DropdownType from "../../types/DropdownType";
import EncounterSubmitterType from "common/types/EncounterSubmitterType";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface IProps {
  patient: MemberType;
  isOpen: boolean;
  onRequestClose: () => void;
  historicalEncounters: EncounterSubmitterType[];
}

interface FormType {
  selectedNurseDropdown: UserType;
  dateTime: DateTime;
  duration: number;
  reason: string;
  visit_type: VisitReasonsEnum;
  tn_visit_type: DropdownType;
  modality: CommunicationTypeEnum;
  total_time: number;
  complexity: string;
  video_call_decline_reason: VideoCallDeclineReasonEnum;
}

function hasMatchInArrays(selectedStaffMemberRoles, encounterSubmitterRoles) {
  let matchFound = false;
  for (let i = 0; i < selectedStaffMemberRoles?.length; i++) {
    if (TH_Roles.includes(selectedStaffMemberRoles[i])) {
      for (let i = 0; i < encounterSubmitterRoles?.length; i++) {
        if (TH_Roles.includes(encounterSubmitterRoles[i])) {
          matchFound = true;
        }
      }
    } else if (NP_Roles.includes(selectedStaffMemberRoles[i])) {
      for (let i = 0; i < encounterSubmitterRoles?.length; i++) {
        if (NP_Roles.includes(encounterSubmitterRoles[i])) {
          matchFound = true;
        }
      }
    } else if (isProviderRole(selectedStaffMemberRoles[i])) {
      for (let i = 0; i < encounterSubmitterRoles?.length; i++) {
        if (isProviderRole(encounterSubmitterRoles[i])) {
          matchFound = true;
        }
      }
    }
  }
  return matchFound;
}

function findOnboardingEncounterForRoles(roles, historicalEncounters) {
  return historicalEncounters?.findIndex((item) => {
    return (
      // item?.encounter?.onboarding === true &&
      [
        VisitReasonsEnum.PROVIDER_INTAKE,
        VisitReasonsEnum.NURSE_INTAKE,
        VisitReasonsEnum.DEVICE_SETUP,
        VisitReasonsEnum.INITIAL_VISIT
      ].includes(item?.visit?.visit_type) &&
      hasMatchInArrays(roles, item?.visit?.submitted_by_roles)
    );
  });
}

const getQueryRolesFromRole = (role: RolesEnum) => {
  if (role === RolesEnum.THN_MANAGER) {
    return { roles: [RolesEnum.TH_NURSE], self: true };
  } else if (role === RolesEnum.NPN_MANAGER) {
    return { roles: [RolesEnum.NP_NURSE], self: false };
  } else if (role === RolesEnum.NURSE_DIRECTOR) {
    return {
      roles: [RolesEnum.THN_MANAGER, RolesEnum.TH_NURSE, RolesEnum.NPN_MANAGER],
      self: false
    };
  } else if (role === RolesEnum.ADMIN) {
    return {
      roles: [
        RolesEnum.NP_NURSE,
        RolesEnum.THN_MANAGER,
        RolesEnum.TH_NURSE,
        RolesEnum.NURSE_PROVIDER,
        RolesEnum.MD_PROVIDER
      ],
      self: false
    };
  } else if (role === RolesEnum.PROVIDER_MANAGER) {
    return {
      roles: [RolesEnum.NURSE_PROVIDER, RolesEnum.MD_PROVIDER],
      self: false
    };
  } else return {};
};

const NewAddPastEncounterModal = ({
  patient,
  isOpen,
  onRequestClose,
  historicalEncounters
}: IProps) => {
  const dispatch = useAppDispatch();
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const startOfMonth = DateTime.now().startOf("month");
  const startOfDay = DateTime.now().startOf("day");

  const [selectedNurseDropdown, setSelectedNurseDropdown] =
    useState<UserType>(undefined);

  const [onboardingEncounterIndex, setOnboardingEncounterIndex] =
    useState<number>(-1);
  const [onboardingEncounterDate, setOnboardingEncounterDate] =
    useState<DateTime>(undefined);

  useEffect(() => {
    if (selectedNurseDropdown === undefined) return;
    const idx = findOnboardingEncounterForRoles(
      selectedNurseDropdown?.user.roles,
      historicalEncounters
    );

    setOnboardingEncounterIndex(idx);
  }, [selectedNurseDropdown, historicalEncounters]);

  useEffect(() => {
    if (onboardingEncounterIndex > -1) {
      setOnboardingEncounterDate(
        DateTime.fromISO(
          // historicalEncounters[onboardingEncounterIndex]?.encounter?.starts_on
          historicalEncounters[onboardingEncounterIndex]?.visit
            ?.encounter_started_on
        )
      );
    }
  }, [onboardingEncounterIndex, historicalEncounters]);

  const { self, roles } = getQueryRolesFromRole(currentRole);

  const { data: user } = useGetAuthenticatedUser();
  const { data: staff } = useGetUserWithQueryParamsQuery({
    roles
  });

  const dropdownStaff = useMemo(() => {
    if (staff === undefined || user === undefined) return [];

    const filtered = staff.filter(
      (item) => item.user.status !== MemberStatusEnum.INACTIVE
    );

    if (self) filtered.push(user);
    return filtered.sort((a, b) =>
      getNameOrUsername(a.user).localeCompare(getNameOrUsername(b.user))
    );
  }, [staff, user]);

  const [
    createEncounterMutation,
    {
      isSuccess: createEncounterIsSuccess,
      isLoading: createEncounterLoading,
      error: createEncounterError,
      reset: resetCreateEncounter
    }
  ] = useCreateEncounterMutation();

  const datepickerDisabled = selectedNurseDropdown === undefined;

  useEffect(() => {
    if (createEncounterIsSuccess) {
      const id = "addPastEncounterSuccess";
      Alert_show({
        dispatch,
        id,
        title: "Success! Encounter Created",
        content: (
          <>
            Your encounter was successfully created! <br />
          </>
        ),
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Done",
            style: "cancel",
            onPress: () => Alert_close({ dispatch, id })
          }
        ]
      });
    }
  }, [createEncounterIsSuccess]);

  useEffect(() => {
    if (isOpen === false) {
      // reset state
      // we can use Formik to do this in the future
      setSelectedNurseDropdown(undefined);
      resetCreateEncounter();
      formik.resetForm();
    }
  }, [isOpen]);

  const validate = (values: FormType) => {
    const isProvider = hasProviderRole(selectedNurseDropdown?.user?.roles);
    const isIntakeNurse = hasIntakeNurseRole(
      selectedNurseDropdown?.user?.roles
    );
    const isTnNurse = hasTnRole(selectedNurseDropdown?.user?.roles);

    const errors = {};

    if (!values["selectedNurseDropdown"]) {
      errors["selectedNurseDropdown"] = "Required";
    }

    if (!values["dateTime"]) {
      errors["dateTime"] = "Required";
    } else if (values["dateTime"]) {
      const minDate =
        onboardingEncounterIndex > -1 && onboardingEncounterDate > startOfMonth
          ? onboardingEncounterDate
          : startOfMonth;

      if (values["dateTime"] < startOfMonth) {
        errors["dateTime"] = "Date must be within the current month";
      } else if (
        onboardingEncounterDate > startOfMonth &&
        values["dateTime"] < minDate
      ) {
        errors["dateTime"] =
          "Date must be after the onboarding encounter on " +
          onboardingEncounterDate.toFormat("MM/dd/yyyy");
      } else if (values["dateTime"] > DateTime.now()) {
        errors["dateTime"] = "Date must be in the past";
      }
    }

    if (isProvider) {
      if (!values["visit_type"]) {
        errors["visit_type"] = "Required";
      }

      if (
        !values["visit_type"] &&
        values["selectedNurseDropdown"] !== null &&
        values["selectedNurseDropdown"]?.user.roles?.findIndex((role) =>
          isProviderRole(role)
        ) > -1 &&
        (currentRole === RolesEnum.ADMIN ||
          currentRole === RolesEnum.PROVIDER_MANAGER)
      ) {
        errors["visit_type"] = "Required";
      }
      if (!values.total_time) {
        errors["total_time"] = "Required";
      }

      if (
        values.modality === CommunicationTypeEnum.PHONE &&
        !values.video_call_decline_reason
      ) {
        errors["video_call_decline_reason"] = "Required";
      }

      if (!values.complexity) {
        errors["complexity"] = "Required";
      }
      if (!values.visit_type) {
        errors["visit_type"] = "Required";
      }
      if (!values.modality) {
        errors["modality"] = "Required";
      }
    } else if (isIntakeNurse) {
      if (!values["duration"]) {
        errors["duration"] = "Required";
      }
    } else if (isTnNurse) {
      if (!values["duration"]) {
        errors["duration"] = "Required";
      } else if (values["duration"] < 1 || values["duration"] > 60) {
        errors["duration"] = "Duration must be between 1 and 60 minutes";
      }

      if (!values["tn_visit_type"]) {
        errors["tn_visit_type"] = "Required";
      }
    }

    return errors;
  };

  const onSubmit = async (values: FormType) => {
    if (hasIntakeNurseRole(selectedNurseDropdown?.user?.roles)) {
      const createEncounterRequestBody = {
        submitted_by: selectedNurseDropdown?.user.user_id,
        patient_id: patient?.patient?.patient_id,
        starts_on: values.dateTime.toUTC().toISO(),
        duration: Number(values.duration),
        reason: VisitReasonsEnum.NURSE_INTAKE,
        modality: CommunicationTypeEnum.PHONE
      };

      const createEncounterResult = await createEncounterMutation({
        body: createEncounterRequestBody
      });

      if ("data" in createEncounterResult) {
        onRequestClose();
      }
    } else if (hasTnRole(selectedNurseDropdown?.user?.roles)) {
      const createEncounterRequestBody = {
        submitted_by: selectedNurseDropdown?.user.user_id,
        modality: CommunicationTypeEnum.PHONE,
        patient_id: patient?.patient?.patient_id,
        starts_on: values.dateTime.toUTC().toISO(),
        duration: Number(values.duration),
        reason: values.tn_visit_type.value
      };

      const createEncounterResult = await createEncounterMutation({
        body: createEncounterRequestBody
      });
      if ("data" in createEncounterResult) {
        onRequestClose();
      }
    } else if (hasProviderRole(selectedNurseDropdown?.user?.roles)) {
      const createEncounterRequestBody = {
        submitted_by: selectedNurseDropdown?.user.user_id,
        patient_id: patient?.patient?.patient_id,
        starts_on: values.dateTime.toUTC().toISO(),
        ...(values.total_time && { duration: Number(values.total_time) }),
        ...(values.duration && { duration: Number(values.duration) }),
        reason: values.visit_type,
        modality: values.modality,
        complexity: `CPT_${values.complexity}`,
        ...(values.video_call_decline_reason &&
          values?.modality === CommunicationTypeEnum.PHONE && {
            video_call_decline_reason: values.video_call_decline_reason
          })
      };
      const createEncounterResult = await createEncounterMutation({
        body: createEncounterRequestBody
      });
      if ("data" in createEncounterResult) {
        onRequestClose();
      }
    }
  };

  const formik = useFormik<FormType>({
    initialValues: {
      selectedNurseDropdown,
      dateTime: DateTime.now()
        .setZone(patient?.patient?.timezone ?? "local")
        .startOf("day")
        .plus({ hours: 10 }),
      // we need to use undefined instead of null or formik will throw a console error
      duration: undefined,
      reason: undefined,
      visit_type: null,
      tn_visit_type: null,
      modality: null,
      total_time: null,
      complexity: null,
      video_call_decline_reason: null
    },
    enableReinitialize: true,
    onSubmit,
    validate
  });

  const hasMultipleRoles: boolean = useMemo(() => {
    return roleHasMultipleMatches(selectedNurseDropdown?.user?.roles);
  }, [selectedNurseDropdown]);

  const onVisitTypeChange = useCallback(
    (event) => {
      // reset complexity if the visit type is changed to a type that doesn't have the current complexity
      if (
        isTruthy(formik.values.complexity) &&
        !getComplexityTypes(event.target.value)?.some(
          (type) => type.value == formik.values?.complexity
        )
      ) {
        formik.setFieldValue("complexity", undefined);
      }
      formik.setFieldValue("visit_type", event.target.value);
    },
    [formik?.values?.visit_type, formik?.values?.complexity, getComplexityTypes]
  );

  return (
    <StyledModal
      isOpen={isOpen}
      contentLabel={"Add Past Encounter"}
      modalHeight={"max-content"}
      shouldCloseOnEsc={true}
      onRequestClose={onRequestClose}
    >
      <Box sx={{ overflowY: "scroll", maxHeight: "100vh" }}>
        <Form onSubmit={formik.handleSubmit}>
          <ModalBody>
            <BodyHeader>Add Past Encounter</BodyHeader>
            <br />
            <Typography variant="body1">Select Staff Member</Typography>
            <br />
            <Autocomplete
              options={dropdownStaff}
              value={formik.values.selectedNurseDropdown}
              getOptionLabel={(user) => {
                return getNameOrUsername(user.user);
              }}
              onChange={(e, value) => {
                formik.setFieldValue("selectedNurseDropdown", value);
                setSelectedNurseDropdown(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formik.errors["selectedNurseDropdown"] !== undefined}
                  helperText={<>{formik.errors["selectedNurseDropdown"]}</>}
                  label="Select Nurse"
                  name="selectNurse"
                />
              )}
            />
            <br />
            <DateTimePicker
              label={
                datepickerDisabled
                  ? "Select staff member first"
                  : "Select encounter date and time"
              }
              value={formik.values.dateTime}
              slotProps={{
                textField: {
                  error: formik.errors["dateTime"] !== undefined,
                  helperText: <>{formik.errors["dateTime"]}</>,
                  // prevent user from typing in the date as this can lead to bugs
                  // see ENG-3757
                  // the below code needs to be here instead of in DateTimePicker.tsx
                  // until this PR is merged https://github.com/mui/material-ui/pull/35088
                  onKeyDown: (e) => {
                    e.preventDefault();
                  }
                }
              }}
              onChange={(newDate: DateTime) => {
                formik.setFieldValue("dateTime", newDate);
              }}
              minDate={
                onboardingEncounterIndex > -1 &&
                onboardingEncounterDate > startOfMonth
                  ? onboardingEncounterDate
                  : startOfMonth
              }
              maxDate={startOfDay}
              disabled={datepickerDisabled}
            />
            {hasProviderRole(selectedNurseDropdown?.user?.roles) &&
              !hasMultipleRoles && (
                <>
                  <TextField
                    style={{ width: "100%" }}
                    value={formik.values.visit_type ?? ""}
                    label="Reason"
                    placeholder="Select Reason"
                    select
                    onChange={onVisitTypeChange}
                  >
                    {newEncounterReasons(RolesEnum.NURSE_PROVIDER).map(
                      (reason) => (
                        <MenuItem key={reason.value} value={reason.value}>
                          {reason.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                  <Flexbox
                    flexDirection="column"
                    gap="8px"
                    minWidth="max-content"
                    mt="20px"
                    mb="20px"
                  >
                    <Typography variant="h6" color="text.primary">
                      Modality
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        border: `1px solid ${gray[300]}`
                      }}
                    >
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={formik.values?.modality ?? ""}
                        onChange={(event) => {
                          if (
                            event.target.value === CommunicationTypeEnum.VIDEO
                          ) {
                            formik.setFieldValue(
                              "video_call_decline_reason",
                              undefined
                            );
                          }
                          formik.setFieldValue("modality", event.target.value);
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            width: "100%",
                            margin: 0,
                            borderBottom: `1px solid ${gray[300]}`
                          }}
                          value="PHONE"
                          control={<Radio />}
                          label={
                            <Typography
                              variant="body1"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px"
                              }}
                            >
                              Audio Call
                              <PhoneOutlined />
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          sx={{
                            width: "100%",
                            margin: 0
                          }}
                          value="VIDEO"
                          control={<Radio />}
                          label={
                            <Typography
                              variant="body1"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px"
                              }}
                            >
                              Video Call
                              <VideocamOutlined />
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </Box>
                  </Flexbox>
                  <Flexbox justifyContent="space-between">
                    <Flexbox flexBasis="48%" gap="8px" flexDirection="column">
                      <FormLabel>
                        <Typography variant="h6" color="text.primary">
                          <Flexbox alignItems="center" gap="2px">
                            Total Time{" "}
                            <CustomTooltip
                              backgroundColor={gray[200]}
                              title={
                                <Typography
                                  variant="body1"
                                  color="text.secondary"
                                  maxWidth="225px"
                                >
                                  This is the total time spent in service of
                                  patients including call attempts, async
                                  messaging, task completion, charting, etc.
                                </Typography>
                              }
                            >
                              <InfoOutlined color="primary" fontSize="small" />
                            </CustomTooltip>
                          </Flexbox>
                        </Typography>
                      </FormLabel>
                      <NumberInput
                        sx={{ width: "100%" }}
                        placeholder="1-60 min"
                        id={"total_time"}
                        name={"total_time"}
                        data-testid="totalTimeInput"
                        value={formik.values?.total_time ?? ""}
                        errorString={formik.errors.total_time}
                        onValueChange={(value) => {
                          formik.setFieldValue("total_time", value);
                        }}
                        min={1}
                        max={60}
                      />
                    </Flexbox>
                  </Flexbox>
                  {(formik.values.visit_type ===
                    VisitReasonsEnum.PROVIDER_INTAKE ||
                    formik.values.visit_type ===
                      VisitReasonsEnum.CHRONIC_DISEASE_MANAGEMENT ||
                    formik.values.visit_type ===
                      VisitReasonsEnum.REACTIVATING) && (
                    <Flexbox gap="8px" flexDirection="column" mt="20px">
                      <FormLabel>
                        <Typography variant="h6" color="text.primary">
                          Complexity
                        </Typography>
                      </FormLabel>
                      <TextField
                        value={formik.values?.complexity ?? ""}
                        placeholder="Select Complexity"
                        select
                        fullWidth
                        error={formik.errors.complexity !== undefined}
                        helperText={formik.errors.complexity}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "complexity",
                            event.target.value
                          );
                        }}
                      >
                        {getComplexityTypes(formik.values.visit_type)?.map(
                          (type) => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Flexbox>
                  )}
                  {formik.values.modality === CommunicationTypeEnum.PHONE &&
                    (formik.values.visit_type ===
                      VisitReasonsEnum.PROVIDER_INTAKE ||
                      formik.values.visit_type ===
                        VisitReasonsEnum.CHRONIC_DISEASE_MANAGEMENT ||
                      formik.values.visit_type ===
                        VisitReasonsEnum.REACTIVATING) && (
                      <Flexbox gap="8px" flexDirection="column" mt="20px">
                        <FormLabel>
                          <Typography variant="h6" color="text.primary">
                            Video Call Decline Reason
                          </Typography>
                        </FormLabel>
                        <TextField
                          value={formik.values?.video_call_decline_reason ?? ""}
                          placeholder="Select Video Call Decline Reason"
                          select
                          fullWidth
                          error={
                            formik.errors.video_call_decline_reason !==
                            undefined
                          }
                          helperText={formik.errors.video_call_decline_reason}
                          onChange={(event) => {
                            formik.setFieldValue(
                              "video_call_decline_reason",
                              event.target.value
                            );
                          }}
                        >
                          {VideoCallDeclineReasons.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Flexbox>
                    )}
                </>
              )}
            {hasIntakeNurseRole(selectedNurseDropdown?.user?.roles) &&
              !hasMultipleRoles && (
                <NumberInput
                  sx={{ width: "100%" }}
                  placeholder="1-60 min"
                  id={"pastTimeSpent"}
                  name={"timeSpent"}
                  data-testid="timeSpentInput"
                  label={"Duration"}
                  value={formik.values.duration}
                  onValueChange={(value) => {
                    formik.setFieldValue("duration", value);
                  }}
                  min={1}
                  max={60}
                />
              )}
            {hasTnRole(selectedNurseDropdown?.user?.roles) &&
              !hasMultipleRoles && (
                <>
                  <NumberInput
                    sx={{ width: "100%" }}
                    placeholder="1-60 min"
                    id={"pastTimeSpent"}
                    name={"pastTimeSpent"}
                    data-testid="timeSpentInput"
                    label={"Duration"}
                    value={formik.values.duration}
                    onValueChange={(value) => {
                      formik.setFieldValue("duration", value);
                    }}
                    min={1}
                    max={60}
                  />
                  <br />
                  {hasTnRole(selectedNurseDropdown?.user?.roles) && (
                    <StyledSelect
                      width="220px"
                      options={tnVisitTypeReasons()}
                      onChange={(e) => formik.setFieldValue("tn_visit_type", e)}
                      value={formik.values?.["tn_visit_type"]}
                      placeholder={"Visit Type"}
                    />
                  )}
                </>
              )}
            {hasMultipleRoles && (
              <Typography variant="body1" color="red">
                Error: Selected staff member has conflicting user roles. Please
                select a staff member with only one role
              </Typography>
            )}
            {createEncounterError && (
              <ErrorComponent
                error={createEncounterError}
                showErrorResponseMessage={true}
              />
            )}
            <br />
            <br />
            <Typography
              sx={{ fontStyle: "italic" }}
              variant="body1"
              color="darkGrey"
            >
              Please contact your manager or administrator for encounter
              amendments dating to before this month (those have already been
              billed for)
            </Typography>
          </ModalBody>
          <ModalFooter>
            <ModalFooterButtons>
              <WhiteButton onClick={onRequestClose} type="button">
                Cancel
              </WhiteButton>
              <TurqoiseButton
                loading={createEncounterLoading}
                disabled={!formik.isValid || !formik.dirty}
                type="submit"
              >
                Create Encounter
              </TurqoiseButton>
            </ModalFooterButtons>
          </ModalFooter>
        </Form>
      </Box>
    </StyledModal>
  );
};

export default NewAddPastEncounterModal;
