import { useMemo } from "react";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import { MenuItem, TextField } from "@mui/material";
import MemberType from "common/types/MemberType";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { useGetProvidersMetadataQuery } from "common/services/ProvidersMetadataService";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { getNameOrUsername, isFalsy } from "common/helpers/helpers";
import { ErrorText } from "../../../styling";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux";
import { setAnswer } from "common/redux/VisitsSlice";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { isDisabled } from "../VisitHelper";

interface IProps {
  member: MemberType;
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const VisitFieldProviderDropdown = ({ member, field, visit }: IProps) => {
  const dispatch = useAppDispatch();

  const { answers } = useSelector((state: RootState) => state.visits);

  const { data, isFetching, isSuccess } = useGetProvidersMetadataQuery(
    {
      state: member?.patient?.address?.state,
      linked_entities: [MemberLinkedEntitiesEnum.PROVIDER]
    },
    {}
  );

  const sortedProviders = useMemo(() => {
    if (data === undefined) return;
    return [...data].sort((a, b) => {
      return a?.provider?.last?.localeCompare(b?.provider?.last);
    });
  }, [data]);

  const disabled = isDisabled(visit);

  return (
    <>
      {isFetching && <LoadingFallback count={5} />}
      {isFalsy(member?.patient?.address?.state) && (
        <ErrorText>
          Error: no state found in RemoteIQ for this member. Please update the
          address.
        </ErrorText>
      )}
      {isSuccess && sortedProviders?.length === 0 && (
        <ErrorText>
          {`Error: no providers found in member's state of residence.`}
        </ErrorText>
      )}
      {isSuccess && sortedProviders?.length > 0 && (
        <TextField
          value={answers[field.field_id] ?? ""}
          label="Provider"
          disabled={disabled}
          select
          fullWidth
          onChange={(event) => {
            dispatch(
              setAnswer({
                id: field.field_id,
                value: event.target.value
              })
            );
          }}
        >
          {sortedProviders?.map((provider) => (
            <MenuItem
              key={provider.provider.user_id}
              value={getNameOrUsername(provider?.provider)}
            >
              {getNameOrUsername(provider?.provider)}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};

export default VisitFieldProviderDropdown;
