import mixPanel from "mixpanel-browser";
import { AnalyticsBrowser } from "@segment/analytics-next";

import EnvVars from "../../config/EnvVars";
import { AnalyticsHelper_getSessionID } from "./AnalyticsHelper.common";

const segmentClient =
  EnvVars.REACT_APP_SEGMENT_ENABLED &&
  AnalyticsBrowser.load({
    writeKey: EnvVars.REACT_APP_SEGMENT_API_KEY
  });

mixPanel.init(EnvVars.REACT_APP_MIXPANEL_TOKEN, {
  track_pageview: true,
  persistence: "localStorage"
});

export const AnalyticsHelper_setIsTest = (isTest: boolean) => {
  if (isTest === true) {
    mixPanel.disable();
  }
};

export const AnalyticsHelper_logEvent = (
  name: string,
  params?: { [key: string]: any }
) => {
  mixPanel.track(name, params);
};

export const AnalyticsHelper_logSegmentEvent = (
  name: string,
  params?: { [key: string]: any }
) => {
  EnvVars.REACT_APP_SEGMENT_ENABLED && segmentClient.track(name, params);
};

export const AnalyticsHelper_logScreenView = (name: string) => {
  mixPanel.track(name);
};

export const AnalyticsHelper_identify = async (member_id: string) => {
  EnvVars.REACT_APP_SEGMENT_ENABLED && segmentClient.identify(member_id);

  // https://github.com/mixpanel/mixpanel-js/issues/295#issuecomment-2023239738
  if (!mixPanel.people) return;

  if (member_id) {
    mixPanel.identify(member_id);
    mixPanel.people.set("member_id", member_id);
    mixPanel.people.set("user_id", member_id);
    mixPanel.people.set("name", member_id);
    mixPanel.register({ member_id, name: member_id });
  }

  const session_id = await AnalyticsHelper_getSessionID();
  mixPanel.people.set("device_id", session_id);
  mixPanel.register({ member_id, name: member_id, device_id: session_id });
};

export const AnalyticsHelper_optOutTracking = () => {
  mixPanel.optOutTracking();
};

export const AnalyticsHelper_reset = () => {
  mixPanel.reset();
  mixPanel.people.unset("member_id");
  mixPanel.people.unset("user_id");

  AnalyticsHelper_getSessionID().then((session_id) => {
    mixPanel.people.set("device_id", session_id);
  });

  EnvVars.REACT_APP_SEGMENT_ENABLED && segmentClient.identify(null);
};
