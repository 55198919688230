import { ChangeEvent, useMemo } from "react";
import { PHONE_NUMBER_REGEXP, maskPhoneNumber } from "common/helpers/helpers";
import { TextField, TextFieldProps } from "@mui/material";

const PhoneInputField = (props: TextFieldProps) => {
  const { value, onChange } = props;

  const valueFinal = useMemo(() => {
    if (typeof value === "string") return maskPhoneNumber(value);
  }, [value]);

  function onValueChange(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    if (onChange !== undefined) onChange(event);
  }

  return (
    <TextField
      {...props}
      onChange={(event) => {
        if (PHONE_NUMBER_REGEXP.test(event.target.value)) {
          onValueChange(event);
        }
      }}
      value={valueFinal ?? ""}
      autoComplete="tel-national"
      slotProps={{ htmlInput: { maxLength: 16 } }}
      fullWidth
    />
  );
};

export default PhoneInputField;
